import axios, { AxiosInstance } from 'axios';

const env = (window as any)._env_;

// Create a new Axios instance
export const api_prov: AxiosInstance = axios.create({
  baseURL: env?.REACT_APP_API_PROV_URL || process.env.REACT_APP_API_PROV_URL,
  headers: {
    'X-API-Key': env?.REACT_APP_API_KEY_DEPLOYMENT || process.env.REACT_APP_API_KEY_DEPLOYMENT,
    'Content-Type': 'application/json',
  },
});

// Create a new Axios instance
export const api_license: AxiosInstance = axios.create({
  baseURL: env?.REACT_APP_API_LIC_URL || process.env.REACT_APP_API_LIC_URL,
  headers: {
    'X-API-Key': env?.REACT_APP_API_KEY_NETSUITE || process.env.REACT_APP_API_KEY_NETSUITE,
    'Content-Type': 'application/json',
  },
});
