export interface Product {
    productCode: string;
    admins: {
        email: string;
    }[];
    startDate: string;
    endDate: string;
    licenseCount: number;
    sourceId: string;
};

export interface ProductSection {
    selectedProject: ProductService | null;
    licenseCount: number;
    adminInputs: string[];
}

export interface ProductService {
    id: number;
    name: string;
    productCode: string;
}

export const InitialProductSection: ProductSection = {
    selectedProject: null,
    licenseCount: 0,
    adminInputs: [],
};