export interface Deal {
    source: string;
    startDate: Date;
    endDate: Date;
    stage: string;
}

export const InitialDeal: Deal = {
    source: '',
    startDate: new Date(),
    endDate: new Date(),
    stage: '',
};