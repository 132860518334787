import React, { useState, useEffect, useRef } from 'react';
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TextField,
  CircularProgress,
  Button,
  TablePagination,
  TableSortLabel,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { PendingProvisioning } from '../models/PendingProvisioning';
import ProvisioningService from '../services/ProvisioningService';
import CloseIcon from '@mui/icons-material/Close';
import HubspotCustomIcon from './shared/HubSpotCustomIcon';

const columns = [
  { id: 'name', label: 'Name', minWidth: 150 },
  { id: 'country', label: 'Country', minWidth: 100 },
  { id: 'products', label: 'Products', minWidth: 150 },
  { id: 'requestDate', label: 'Request Date', minWidth: 130 },
  { id: 'sourceLink', label: 'Hubspot', minWidth: 100 },
];

const Provisioning: React.FC = () => {
  const [selectedRow, setSelectedRow] = useState<PendingProvisioning | null>(null);
  const [filterText, setFilterText] = useState('');
  const [rows, setRows] = useState<PendingProvisioning[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState('requested');
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const drawerRef = useRef<HTMLDivElement>(null);
  const rootRef = useRef<HTMLDivElement>(null);

  const history = useHistory();
  const handleOpenDeploymentWizard = () => {
    // Navigate to the "/deployment" route and pass the selectedRow as state
    history.push('/deployment', { selectedRow });
  };

  const handleRowSelection = (event: React.MouseEvent<unknown>, rowData: PendingProvisioning) => {
    if (selectedRow && selectedRow.id == rowData.id) {
      setSelectedRow(null);
    } else {
      setSelectedRow(rowData);
    }
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.target.value);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleCloseDrawer = () => {
    setSelectedRow(null);
  };

  const handleRootClick = (event: React.MouseEvent<unknown>) => {
    if (
      selectedRow &&
      drawerRef.current &&
      rootRef.current &&
      !drawerRef.current.contains(event.target as Node) &&
      !rootRef.current.contains(event.target as Node)
    ) {
      setSelectedRow(null);
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    setLoading(true);
    ProvisioningService.getRequest()
      .then((data: unknown) => {
        const rowDataArray: PendingProvisioning[] = data as PendingProvisioning[];
        const filteredData = rowDataArray.filter((item) => {
          const searchText = filterText.toLowerCase();
          return Object.values(item).some((value) =>
            value.toString().toLowerCase().includes(searchText)
          );
        });

        const sortedData = filteredData.sort((a, b) => {
          const isAsc = order === 'asc';
          if (a[orderBy as keyof PendingProvisioning] < b[orderBy as keyof PendingProvisioning]) {
            return isAsc ? -1 : 1;
          }
          if (a[orderBy as keyof PendingProvisioning] > b[orderBy as keyof PendingProvisioning]) {
            return isAsc ? 1 : -1;
          }
          return 0;
        });
        setRows(sortedData);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, [filterText, orderBy, order]);

  return (
    <div ref={rootRef} onClick={handleRootClick}>
      <AppBar position="static" className="PageTitle-section" color="transparent" elevation={0}>
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Pending deployments
          </Typography>
          <TextField label="Search Filter" value={filterText} onChange={handleFilterChange} className="MainSearch-table" />
        </Toolbar>
      </AppBar>
      <div className="PageContent-section">
        {loading ? (
          <div className="MainLoading-section">
            <CircularProgress />
          </div>
        ) : (
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} align="left" style={{ minWidth: column.minWidth }}>
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : 'asc'}
                        onClick={() => handleRequestSort(column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                  <TableRow
                    key={row.id}
                    onClick={(event) => handleRowSelection(event, row)}
                    style={{
                      cursor: 'pointer',
                      backgroundColor: selectedRow && selectedRow.id === row.id ? 'lightgray' : 'white',
                    }}
                  >
                    {columns.map((column) => (
                      <TableCell key={column.id} align="left">
                        {column.id === 'sourceLink' ? (
                          <HubspotCustomIcon />
                        ) : ''}
                        {column.id === 'requestDate' ? (
                          format(new Date(row[column.id]), 'yyyy-MM-dd HH:mm:ss')
                        ) : (
                          row[column.id]
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Drawer anchor="right" variant="persistent" open={selectedRow !== null} onClose={handleCloseDrawer} hideBackdrop ref={drawerRef}>
        <AppBar position="static" color="default" elevation={0}>
          <Toolbar>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              {selectedRow ? `${selectedRow.name} Details` : 'Deployment Details'}
            </Typography>
            <IconButton edge="end" color="inherit" onClick={handleCloseDrawer} aria-label="close drawer">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className="SelectedDetails-section">
          {selectedRow && (
            <>
              <Typography variant="body1">
                <span className="PropertyTitle-section">Name</span>
                {selectedRow.name}
              </Typography>
              <Typography variant="body1">
                <span className="PropertyTitle-section">Country</span>
                {selectedRow.country}
              </Typography>
              <Typography variant="body1">
                <span className="PropertyTitle-section">Products</span>
                {selectedRow.products}
              </Typography>
              <Typography variant="body1">
                <span className="PropertyTitle-section">Hubspot Deal</span>
                <HubspotCustomIcon />
                {selectedRow.sourceLink}
              </Typography>
              <Typography variant="body1">
                <span className="PropertyTitle-section">Request Date</span>
                {format(new Date(selectedRow.requestDate), 'yyyy-MM-dd HH:mm:ss')}
              </Typography>
              <Button variant="outlined" onClick={handleCloseDrawer} className="button-container">
                Return
              </Button>
              <Button variant="contained" onClick={handleOpenDeploymentWizard} className="button-container button-space-before">
                Deployment Wizard
              </Button>
            </>
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default Provisioning;
