import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';

const TopMenu: React.FC = () => {
  return (
    <AppBar position="fixed" className="Main-menu">
      <Toolbar>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          <Link to="/" color="inherit" style={{ textDecoration: 'none', color: 'inherit', marginRight: 2 }}>
            Qoria Licensify
          </Link>
        </Typography>
        <Button component={Link} to="/" color="inherit" sx={{ textDecoration: 'none', color: 'inherit', mr: 2 }}>
          Home
        </Button>
        <Button component={Link} to="/provisioning" color="inherit" sx={{ textDecoration: 'none', color: 'inherit', mr: 2 }}>
          Provisioning
        </Button>
        <Button component={Link} to="/licensing" color="inherit" sx={{ textDecoration: 'none', color: 'inherit' }}>
          Licensing
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default TopMenu;
