import React, { useState } from 'react';
import {
  Typography,
  Button,
  Grid,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import RequestDetails from './deploymentsSteps/RequestDetails';
import {
  DeploymentDisplay,
} from './deploymentsSteps/DeploymentDisplay';
import {
  Consumer,
  ConsumerResponse
} from '../models/ConsumerResponse';
import { ConsumerRequest } from '../models/ConsumerRequest';
import { Product } from '../models/Product';
import ProvisioningService from '../services/ProvisioningService';

interface LocationState {
  selectedRow: {
    id: string;
    name: string;
    country: string;
    products: string;
    requestDate: string;
    sourceLink: string;
    data: string;

    consumerRequest: ConsumerRequest;

    // DeploymentDetails page
    isMAT: boolean;
    isMultiTenanted: boolean;
    isNew: boolean;
    deployedConsumers: Consumer[];
  };
}

interface DeploymentWizardProps {
  onClose: () => void;
}

const DeploymentWizard: React.FC<DeploymentWizardProps> = ({ onClose }) => {
  const location = useLocation<LocationState>();
  const selectedRow = location.state?.selectedRow;
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(true);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleDeploy = () => {
    // @@@ To be populated by previous steps
    let dataObj = JSON.parse(selectedRow.data);
    let products = dataObj.products as Product[];
    selectedRow.consumerRequest = {
      consumers: [
        {
          qid: dataObj.qid,
          name: dataObj.name,
          regionCountry: "test-country",
          regionState: "test-region",
          customerType: "School",
          isMultiTenanted: false,
          products: products
        }
      ]
    };
    selectedRow.isMultiTenanted = true;
    //selectedRow.isMultiTenanted = false;
    selectedRow.isMAT = true;
    //selectedRow.isMAT = false;

    ProvisioningService.triggerDeployment(selectedRow.id, JSON.stringify(selectedRow.consumerRequest))
      .then((data: unknown) => {
        console.log(data);
        let deployResponse = data as ConsumerResponse;
        selectedRow.deployedConsumers = deployResponse.consumers;
        console.log(selectedRow.deployedConsumers);
        setLoading(false);
        handleNext();
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }

  const steps = ['Step 1', 'Step 2', 'Step 3'];

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <div>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <RequestDetails selectedRow={selectedRow} />
              </Grid>
              <Grid item xs={8}>
                <div className="right-side-details">
                  <Typography variant="body1" className="step-title">
                    Select an option:
                  </Typography>
                  <RadioGroup>
                    <FormControlLabel
                      value="option1"
                      control={<Radio />}
                      label={`Deploy for serial and appliance Id. This QID is already associated with this configuration.`}
                      onChange={() => {
                        selectedRow.isNew = false;
                      }}
                    />
                    <FormControlLabel
                      value="option2"
                      control={<Radio />}
                      label={`Deploy for serial and appliance Id. This QID is already associated with a tenant under this configuration.`}
                      onChange={() => {
                        selectedRow.isNew = false;
                      }}
                    />
                    <FormControlLabel
                      value="option3"
                      control={<Radio />}
                      label={`Deploy for serial and appliance Id. The HubSpot deal is associated with this reseller.`}
                      onChange={() => {
                        selectedRow.isNew = false;
                      }}
                    />
                    <FormControlLabel
                      value="option4"
                      control={<Radio />}
                      label={`Deploy for serial and appliance Id. This QID is a known child of this group.`}
                      onChange={() => {
                        selectedRow.isNew = false;
                      }}
                    />
                    <FormControlLabel
                      value="option5"
                      control={<Radio />}
                      label="None of the above"
                      onChange={() => {
                        selectedRow.isNew = true;
                      }}
                    />
                  </RadioGroup>
                </div>
              </Grid>
            </Grid>
          </div>
        );
      case 1:
        return (
          <div>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <RequestDetails selectedRow={selectedRow} />
              </Grid>
              <Grid item xs={8}>
                <div className="right-side-details">
                  <Typography variant="body1" className="step-title">
                    Configure deployment:
                  </Typography>
                  <Grid item xs={6} className="text-field">
                    <TextField label="Field 1" fullWidth />
                  </Grid>
                  <Grid item xs={6} className="text-field">
                    <TextField label="Field 2" fullWidth />
                  </Grid>
                  <Grid item xs={6} className="text-field">
                    <TextField label="Field 3" fullWidth />
                  </Grid>
                  <Grid item xs={6} className="text-field">
                    <TextField label="Field 4" fullWidth />
                  </Grid>
                  <Grid item xs={6} className="text-field">
                    <TextField label="Field 5" fullWidth />
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </div>
        );
      case 2:
        return (
          <div>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <RequestDetails selectedRow={selectedRow} />
              </Grid>
              <Grid item xs={8}>
                <div className="right-side-details">
                  <Typography variant="body1" className="step-title">
                    Select schools:
                  </Typography>
                  <Grid item xs={6} className="text-field">
                    <TextField label="Field 1" fullWidth />
                  </Grid>
                  <Grid item xs={6} className="text-field">
                    <TextField label="Field 2" fullWidth />
                  </Grid>
                  <Grid item xs={6} className="text-field">
                    <TextField label="Field 3" fullWidth />
                  </Grid>
                  <Grid item xs={6} className="text-field">
                    <TextField label="Field 4" fullWidth />
                  </Grid>
                  <Grid item xs={6} className="text-field">
                    <TextField label="Field 5" fullWidth />
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </div>
        );
      default:
        return (
          <div>
            <Typography variant="body1" className="default-content">
              Default Content.
            </Typography>
          </div>
        );
    }
  };

  return (
    <div className="deployment-wizard">
      <div className="wizard-container">
        {activeStep === steps.length ? (
          <div className="progress-container">
            <DeploymentDisplay selectedRow={selectedRow} />
            <Button variant="contained" component={Link} to="/provisioning" className="return-button">
              Return
            </Button>
          </div>
        ) : (
          <div>
            {getStepContent(activeStep)}
            <div className="button-container">
              <Button disabled={activeStep === 0} onClick={handleBack} className="button-space-after">
                Back
              </Button>
              {activeStep === steps.length - 1 ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={function (event) { handleDeploy(); }}
                  className="button-space-after"
                >
                  Deploy
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className="button-space-after"
                >
                  Next
                </Button>
              )}
              <Button onClick={handleReset} className="button-space-after">
                Reset
              </Button>
            </div>
          </div>
        )}
      </div>
    </div >
  );
};

export default DeploymentWizard;
