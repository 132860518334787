export interface Customer {
    qid: string;
    name: string;
    customerType: string;
    regionCountry: string;
    regionState: string;
    salesRep: string;
}

export const InitialCustomer: Customer = {
    qid: '',
    name: '',
    customerType: '',
    regionCountry: '',
    regionState: '',
    salesRep: '',
};