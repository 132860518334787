import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from './components/Home';
import Provisioning from './components/Provisioning';
import Licensing from './components/Licensing';
import DeploymentWizard from './components/DeploymentWizard';
import TopMenu from './components/TopMenu';

const App: React.FC = () => {
  return (
    <div>
      <ToastContainer />
      <TopMenu />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/provisioning" component={Provisioning} />
        <Route path="/deployment" component={DeploymentWizard} />
        <Route path="/licensing" component={Licensing} />
      </Switch>
    </div>
  );
};

export default App;
