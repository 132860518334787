import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Grid, Typography, Box, IconButton, CircularProgress, Autocomplete, Chip } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProvisioningService from '../services/ProvisioningService';
import DeleteIcon from '@mui/icons-material/Delete';
import { useLocation } from 'react-router-dom';
import { Customer, InitialCustomer } from '../models/Customer';
import { Deal, InitialDeal } from '../models/Deal';
import { ProductSection, ProductService, InitialProductSection } from '../models/Product';
import { LicenseRequest } from '../models/LicenseRequest';
import { parseISO } from 'date-fns';

const Licensing: React.FC = () => {
  const [purchaser, setPurchaser] = useState<Customer>(InitialCustomer);
  const [deal, setDeal] = useState<Deal>(InitialDeal);
  const [products, setProducts] = useState<ProductSection[]>([InitialProductSection]);
  const [productOptions, setProductOptions] = useState<ProductService[]>([]);
  const [otherProductCode, setOtherProductCode] = useState('');
  const [submitedPressed, setsubmitedPressed] = useState(false);
  const [loading, setLoading] = useState(false);


  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sourceIdParam = Array.from(queryParams.keys()).find(
    (key) => key.toLowerCase() === 'sourceid'
  );
  const qidParam = Array.from(queryParams.keys()).find(
    (key) => key.toLowerCase() === 'qid'
  );
  const qidInitValue = (qidParam) ? queryParams.get(qidParam) : null;
  const sourceIdInitValue = (sourceIdParam) ? queryParams.get(sourceIdParam) : null;

  useEffect(() => {
    if (qidInitValue) {
      setPurchaser((prevPurchaser) => ({
        ...prevPurchaser,
        qid: qidInitValue,
      }));
    }

    if (sourceIdInitValue) {
      setDeal((prevDeal) => ({
        ...prevDeal,
        source: sourceIdInitValue,
      }));
    }
  }, [location.search]);

  useEffect(() => {
    const fetchProductOptions = async () => {
      try {
        const productOptions = await ProvisioningService.getProducts();
        const options = productOptions.concat({ name: 'Other', productCode: 'Other' });
        setProductOptions(options);
      } catch (error) {
        console.error('Error fetching product options:', error);
      }
    };

    fetchProductOptions();
  }, []);

  const handlePurchaserChange = (field: keyof Customer, value: string) => {
    setPurchaser((prevPurchaser) => ({
      ...prevPurchaser,
      [field]: value,
    }));
  };

  const handleDealChange = (field: keyof Deal, value: string | Date | null) => {
    setDeal((prevDeal) => ({
      ...prevDeal,
      [field]: value,
    }));

    if (field.toString() === 'startDate' && value instanceof Date && value > deal.endDate) {
      field = 'endDate';
      setDeal((prevDeal) => ({
        ...prevDeal,
        [field]: value,
      }));
    }
  };

  const handleProductChange = (index: number, field: keyof ProductSection, value: ProductService | string | number | string[] | null) => {
    const updatedProducts = [...products];
    updatedProducts[index] = {
      ...updatedProducts[index],
      [field]: value,
    };
    setProducts(updatedProducts);
  };

  const handleRemoveProduct = (index: number) => {
    if (products.length > 1) {
      const updatedProducts = [...products];
      updatedProducts.splice(index, 1);
      setProducts(updatedProducts);
    }
  };

  const handleSubmit = async () => {
    try {
      // Validate form fields
      if (!validateForm()) {
        setsubmitedPressed(true);
        toast.error('Please fill in all required fields');
        return;
      }

      setLoading(true);

      const requestBody: LicenseRequest = {
        customer: purchaser,
        licenseEvents: products.map((product) => ({
          sourceId: deal.source,
          productCode: product.selectedProject?.productCode === 'Other' ? otherProductCode : product.selectedProject?.productCode || '',
          startDate: new Date(parseISO(deal.startDate.toISOString())),
          endDate: new Date(parseISO(deal.endDate.toISOString())),
          licenseCount: product.licenseCount,
          state: deal.stage,
          eventDate: new Date().toISOString(),
          admins: product.adminInputs.map((input) => ({ email: input })),
        })),
      };

      const response = await ProvisioningService.submitLicenses(requestBody);
      console.log('Licensing submission response:', response.data);
      toast.success(
        'Success! An event has been recorded in licensing and will be available in pending deployments shortly.'
      );

      resetForm();

    } catch (error) {
      console.error('Licensing submission error:', error);
      setLoading(false);
      toast.error('Licensing submission error!');
    }
  };

  const validateForm = () => {
    // Check if any required fields are empty
    if (
      !purchaser.qid ||
      !purchaser.name ||
      !purchaser.customerType ||
      !purchaser.regionCountry ||
      !purchaser.regionState ||
      !purchaser.salesRep ||
      !deal.source ||
      !deal.stage ||
      !deal.startDate ||
      !deal.endDate ||
      products.some((product) => {
        if (product.selectedProject?.productCode === "Other") {
          return !otherProductCode || !product.licenseCount;
        } else {
          return !product.selectedProject?.productCode || !product.licenseCount;
        }
      })
    ) {
      return false;
    }

    return true;
  };

  const resetForm = () => {
    // Reset the form after successful submission
    setLoading(false);
    setPurchaser(InitialCustomer);
    setDeal(InitialDeal);
    setProducts([InitialProductSection]);
    setOtherProductCode('');
    setsubmitedPressed(false);
  }

  const handleOtherProductCodeChange = (value: string) => {
    setOtherProductCode(value);
  };

  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <Typography variant="h4" component="h1" align="center">
          Add Licenses
        </Typography>
      </Box>
      {loading ? (
        <div className="MainLoading-section">
          <CircularProgress />
        </div>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6">Purchaser</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="QID"
              fullWidth
              className={!purchaser.qid.length && submitedPressed ? 'error' : ''}
              value={purchaser.qid}
              onChange={(e) => handlePurchaserChange('qid', e.target.value)}
              disabled={!!qidInitValue}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Name"
              fullWidth
              className={!purchaser.name.length && submitedPressed ? 'error' : ''}
              value={purchaser.name}
              onChange={(e) => handlePurchaserChange('name', e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Type"
              fullWidth
              className={!purchaser.customerType.length && submitedPressed ? 'error' : ''}
              value={purchaser.customerType}
              onChange={(e) => handlePurchaserChange('customerType', e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Country"
              fullWidth
              className={!purchaser.regionCountry.length && submitedPressed ? 'error' : ''}
              value={purchaser.regionCountry}
              onChange={(e) => handlePurchaserChange('regionCountry', e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="State"
              fullWidth
              className={!purchaser.regionState.length && submitedPressed ? 'error' : ''}
              value={purchaser.regionState}
              onChange={(e) => handlePurchaserChange('regionState', e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Sales Representative"
              fullWidth
              className={!purchaser.salesRep.length && submitedPressed ? 'error' : ''}
              value={purchaser.salesRep}
              onChange={(e) => handlePurchaserChange('salesRep', e.target.value)}
              required
            />
          </Grid>
          {/* Deal Section */}
          <Grid item xs={12}>
            <Typography variant="h6">Hubspot Deal</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Source Id"
              fullWidth
              className={!deal.source.length && submitedPressed ? 'error' : ''}
              value={deal.source}
              onChange={(e) => handleDealChange('source', e.target.value)}
              disabled={!!sourceIdInitValue}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Stage"
              fullWidth
              className={!deal.stage.length && submitedPressed ? 'error' : ''}
              value={deal.stage}
              onChange={(e) => handleDealChange('stage', e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Start Date"
                value={deal.startDate}
                minDate={new Date(new Date().getFullYear(), 0, 1)} // Minimum date set to first day of current year
                onChange={(date) => handleDealChange('startDate', date)}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="End Date"
                value={deal.endDate}
                minDate={deal.startDate}
                onChange={(date) => handleDealChange('endDate', date)}
              />
            </LocalizationProvider>
          </Grid>
          {/* Products Section */}
          <Grid item xs={12}>
            <Typography variant="h6">Products and Admins</Typography>
          </Grid>
          {products.map((product, index) => (
            <React.Fragment key={index}>
              <Grid item xs={4}>
                <Autocomplete
                  options={productOptions}
                  value={product.selectedProject || null}
                  onChange={(e, value) => handleProductChange(index, 'selectedProject', value ?? null)}
                  getOptionLabel={(option: ProductService) => option.name || ''}
                  renderInput={(params) => <TextField {...params} required label="Selected Project" fullWidth />}
                />
              </Grid>
              {product.selectedProject?.productCode === 'Other' && (
                <Grid item xs={4}>
                  <TextField
                    placeholder="Provide new product code"
                    label="Product Code"
                    fullWidth
                    value={otherProductCode}
                    onChange={(e) => handleOtherProductCodeChange(e.target.value)}
                    required
                  />
                </Grid>
              )}
              <Grid item xs={4}>
                <TextField
                  label="License Count"
                  type="number"
                  fullWidth
                  className={product.licenseCount < 1 && submitedPressed ? 'error' : ''}
                  value={product.licenseCount}
                  onChange={(e) => handleProductChange(index, 'licenseCount', e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={10}>
                <Autocomplete
                  multiple
                  freeSolo
                  options={[]} // Empty array since we are using freeSolo mode
                  value={product.adminInputs}
                  onChange={(e, value) => handleProductChange(index, 'adminInputs', value)}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => <Chip label={option} {...getTagProps({ index })} />)
                  }
                  renderInput={(params) => <TextField {...params} label="Admin Inputs" fullWidth />}
                />
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  aria-label="remove product"
                  onClick={() => handleRemoveProduct(index)}
                  disabled={products.length === 1}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </React.Fragment>
          ))}
          <Grid item xs={12}>
            <Button onClick={() => setProducts([...products, InitialProductSection])}>Add Product</Button>
          </Grid>
          {/* Buttons */}
          <Grid item xs={6}>
            <Button variant="outlined" onClick={resetForm} className="button-space-after">
              Reset
            </Button>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default Licensing;
