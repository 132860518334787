import React from 'react';
import { Typography, Grid, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { Link } from 'react-router-dom';
import { ArrowForward, Link as LinkIcon } from '@mui/icons-material';
import Box from '@mui/material/Box';

const Home: React.FC = () => {
  return (
    <div>
      <div className="HomeBaner-section">
        <Typography variant="h4" align="center">
          Welcome to Qoria Licensify
        </Typography>
        <Typography variant="h6" align="center" className="Home-subtitle">
          Manage Licences and Provisioning
        </Typography>
      </div>
      <Grid container spacing={2} justifyContent="center" className="Home-grid">
        <Grid item xs={12} sm={6} md={3} className="Home-grid-item">
          <div>
            <u>Useful links</u>
            <List>
              <ListItem component="a" href="https://app.hubspot.com/" target="_blank" button>
                <ListItemIcon>
                  <LinkIcon />
                </ListItemIcon>
                <ListItemText primary="Hubspot" />
              </ListItem>
              <ListItem component="a" href="https://partner.smoothwall.net/cgi-bin/login.cgi" target="_blank" button>
                <ListItemIcon>
                  <LinkIcon />
                </ListItemIcon>
                <ListItemText primary="PartnerNet" />
              </ListItem>
              <ListItem component="a" href="https://smoothwall.zendesk.com/" target="_blank" button>
                <ListItemIcon>
                  <LinkIcon />
                </ListItemIcon>
                <ListItemText primary="Zendesk" />
              </ListItem>
            </List>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3} className="Home-grid-item">
          <Link to="/provisioning" className="Home-link">
            <div className="Home-card">
              <div>
                <Typography variant="h5">
                  Provisioning
                </Typography>
                <Typography variant="body1">
                  Start exploring provisioning requests or perform other actions here.
                </Typography>
              </div>
              <Box
                component="div"
                display="flex"
                justifyContent="center"
                alignItems="center"
                width={50}
                height={50}
                borderRadius="50%"
                className="Home-card-icon"
              >
                <ArrowForward fontSize="large" />
              </Box>
            </div>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={3} className="Home-grid-item">
          <Link to="/licensing" className="Home-link">
            <div className="Home-card">
              <div>
                <Typography variant="h5">
                  Licensing
                </Typography>
                <Typography variant="body1">
                  Manage licenses and associated information in this section.
                </Typography>
              </div>
              <Box
                component="div"
                display="flex"
                justifyContent="center"
                alignItems="center"
                width={50}
                height={50}
                borderRadius="50%"
                className="Home-card-icon"
              >
                <ArrowForward fontSize="large" />
              </Box>
            </div>
          </Link>
        </Grid>
      </Grid>
    </div>
  );
};

export default Home;
