import { LicenseRequest } from '../models/LicenseRequest';
import { api_license, api_prov } from './api';

const ProvisioningService = {
  getRequest: async () => {
    const response = await api_prov.get('/api/v1.0/requests');
    return response.data.data;
  },
  getProducts: async () => {
    const response = await api_prov.get('/api/v1.0/services');
    return response.data.data;
  },
  submitLicenses: async (request: LicenseRequest) => {
    const response = await api_license.post('/api/v1.0/licensing/events', request);
    return response.data.data;
  },
  triggerDeployment: async (id: string, request: string) => {
    const response = await api_prov.post('/api/v1.0/requests/' + id, request);
    return response.data.data;
  }
};

export default ProvisioningService;
