import React, { FC, HTMLProps } from 'react';

interface HubspotCustomIconProps extends HTMLProps<HTMLSpanElement> {
  color?: string;
}

const HubspotCustomIcon: FC<HubspotCustomIconProps> = ({ ...props }) => (
  <span className="fa-brands fa-hubspot hubspot-icon" {...props}></span>
);

export default HubspotCustomIcon;
