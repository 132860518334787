import React from 'react';
import { format } from 'date-fns';
import HubspotCustomIcon from '../shared/HubSpotCustomIcon';

interface RequestDetailsProps {
    selectedRow: {
        name: string;
        country: string;
        products: string;
        requestDate: string;
        sourceLink: string;
    };
}

const RequestDetails: React.FC<RequestDetailsProps> = ({ selectedRow }) => {
    return (
        <div className="left-side-details">
            {selectedRow ? (
                <>
                    <div className="detail">
                        <b>Name:</b> {selectedRow.name}
                    </div>
                    <div className="detail">
                        <b>Country:</b> {selectedRow.country}
                    </div>
                    <div className="detail">
                        <b>Product:</b> {selectedRow.products}
                    </div>
                    <div className="detail">
                        <b>Hubspot Deal:</b>
                        <HubspotCustomIcon />
                        {selectedRow.sourceLink}
                    </div>
                    <div className="detail">
                        <b>Request Date:</b>
                        {format(new Date(selectedRow.requestDate), 'yyyy-MM-dd HH:mm:ss')}
                    </div>
                </>
            ) : (
                <>
                    <div className="no-request">No request selected, please return to the list.</div>
                </>
            )}
        </div>
    );
};

export default RequestDetails;
