import React, { useState } from 'react';
import { format } from 'date-fns';
import {
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from '@mui/material';
import { Consumer } from '../../models/ConsumerResponse';

interface State {
  selectedRow: {
    deployedConsumers: Consumer[];

    isMAT: boolean;
    isMultiTenanted: boolean;
    isNew: boolean;
  };
}

const DeploymentDisplay: React.FC<State> = ({ selectedRow }) => {
  let title;
  if (selectedRow.isNew) {
    title = "Deployment in Progress";
  } else {
    title = "Linked to Configuration";
  }

  let tenantMessage;
  if (selectedRow.isMAT && selectedRow.isMultiTenanted) {
    tenantMessage = "Please add the school as a tenant using the Global Support Tool, or have the customer do this in the Admin Panel";
  }

  const columns = [
    { id: 'name', label: 'Name', minWidth: 150 },
    { id: 'serial', label: 'Serial', minWidth: 150 },
    { id: 'applianceId', label: 'Appliance ID', minWidth: 150 },
  ];

  return (<div><Typography variant="body1" className="step-title">
    {title}
  </Typography>
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.id} align="left" style={{ minWidth: column.minWidth }}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedRow.deployedConsumers.map((row) => (
            <TableRow key={row.name}>
              {columns.map((column) => (
                <TableCell key={column.id} align="left">
                  {row[column.id]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <Typography variant="body1" className="tenant-message">
      {tenantMessage}
    </Typography>
  </div>
  );
};

export {
  DeploymentDisplay,
};
